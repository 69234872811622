import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import Baja from "../../Baja";

function CortesPendientesRecibir() {
  const { user } = useContext(AuthContext);
  const URL_PROCESOS_SALIDA = process.env.REACT_APP_URL_PROCESOS_SALIDA;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let comp8 = new Date();
  comp8.setDate(comp8.getDate() + 8);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  let compromiso8 = moment(comp8).format("YYYY-MM-DD");

  const [procesos, setProcesos] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [maquileros, setMaquileros] = useState([]);
  const [selectedMaquilero, setSelectedMaquilero] = useState("");
  const [selectedVencido, setSelectedVencido] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [subtotal, setSubtotal] = useState();
  const [iva, setIva] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [totalEdit, setTotalEdit] = useState();
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_PROCESOS_SALIDA}FechasActivos/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allProcesos = response.data;
        let arrayTabla = allProcesos
          .sort((a, b) => (a.fechaCompromiso > b.fechaCompromiso ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              status: a.status,
              fecha: a.fecha,
              fechaCompromiso: a.fechaCompromiso,
              maquilero: a.proveedores[0].nombre_comercial,
              idMaquilero: a.proveedores[0]._id,
              pedido: a.pedidos[0].idPedido,
              pedidoCliente: a.pedidos[0].pedidoCliente,
              proceso: a.catalogoProcesos[0].name,
              idProceso: a.catalogoProcesos[0]._id,
              corte: a.cortes[0].idCorte,
              cantidad: a.totalPiezas,
              articulo: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente,
              idArticulo: a.articulos[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setProcesos(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROVEEDORES}Maquileros`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMaquileros = response.data;
        setMaquileros(allMaquileros);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    total = 0;
    const data = procesos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        total = total + c.cantidad;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total);
        return [
          c.fecha,
          c.maquilero,
          c.proceso,
          c.articulo,
          c.fechaCompromiso,
          c.corte,
          c.pedidoCliente,
          c.cantidad,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Procesos Pendientes", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Maquilero",
          "Proceso",
          "Articulo",
          "Fecha Comp.",
          "Corte",
          "Pedido Cliente",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("ProcesosPendientes.pdf");
  }

  function excel() {
    const dataExcel = procesos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        return {
          Fecha: c.fecha,
          Maquilero: c.maquilero,
          Proceso: c.proceso,
          Articulo: c.articulo,
          FechaCompromiso: c.fechaCompromiso,
          Corte: c.corte,
          PedidoCliente: c.pedidoCliente,
          Cantidad: c.cantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `ProcesosCorte-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `ProcesosCorte`,
        sheetFilter: [
          "Fecha",
          "Maquilero",
          "Proceso",
          "Articulo",
          "FechaCompromiso",
          "Corte",
          "PedidoCliente",
          "Cantidad",
        ],
        sheetHeader: [
          "Fecha",
          "Maquilero",
          "Proceso",
          "Articulo",
          "FechaCompromiso",
          "Corte",
          "PedidoCliente",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = procesos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        total = total + c.cantidad;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total);
        return [
          c.fecha,
          c.maquilero,
          c.proceso,
          c.articulo,
          c.fechaCompromiso,
          c.corte,
          c.pedidoCliente,
          c.cantidad,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Procesos Pendientes", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Maquilero",
          "Proceso",
          "Articulo",
          "Fecha Comp.",
          "Corte",
          "Pedido Cliente",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Gastos",
          email: mailTo,
          fileName: "ListaGastos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Gastos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Maquilero", field: "maquilero", sortable: true },
    { name: "Proceso", field: "proceso", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Fecha Compromiso", field: "fechaCompromiso", sortable: true },
    { name: "Corte", field: "corte", sortable: true },
    { name: "Pedido Cliente", field: "pedidoCliente", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.maquilero.toLowerCase().includes(search.toLowerCase()) ||
          comment.proceso.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidoCliente.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedMaquilero) {
      computedComments = computedComments.filter((e) =>
        e.idMaquilero.includes(selectedMaquilero)
      );
    }

    if (selectedVencido) {
      if (selectedVencido == "vencido") {
        computedComments = computedComments.filter(
          (e) => e.fechaCompromiso < endDate
        );
      }
      if (selectedVencido == "aTiempo") {
        computedComments = computedComments.filter(
          (e) => e.fechaCompromiso > compromiso8
        );
      }
      if (selectedVencido == "porVencer") {
        computedComments = computedComments.filter(
          (e) => e.fechaCompromiso < compromiso8 && e.fechaCompromiso > endDate
        );
      }
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedMaquilero,
    selectedVencido,
  ]);

  function jalaInfo(id, subtotal, iva, total, concepto, descripcion, fecha) {
    setIdEdit(id);
    setSubtotal(subtotal);
    setIva(iva);
    setTotalEdit(total);
    setSelectedConcepto(concepto);
    setDescripcion(descripcion);
    setFecha(fecha);
    toggleEdit();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    <Button
                      size="sm"
                      href="/MenuProduccion"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Gastos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Procesos Pendientes</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Maquilero</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedMaquilero}
                      onChange={(e) => {
                        setSelectedMaquilero(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {maquileros
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Label>Vencimiento</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedVencido}
                      onChange={(e) => {
                        setSelectedVencido(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="vencido">Vencido</option>
                      <option value="porVencer">Proximo a Venceer</option>
                      <option value="aTiempo">A tiempo</option>
                    </Input>
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.cantidad;
                      return (
                        <tr
                          style={
                            c.fechaCompromiso < endDate
                              ? { backgroundColor: "#ed8c8c" }
                              : c.fechaCompromiso > compromiso8
                              ? { backgroundColor: "white" }
                              : { backgroundColor: "orange" }
                          }
                        >
                          <td>{c.fecha}</td>
                          <td>{c.maquilero}</td>
                          <td>{c.proceso}</td>
                          <td>{c.articulo}</td>
                          <td>{c.fechaCompromiso}</td>
                          <td>{c.corte}</td>
                          <td>{c.pedidoCliente}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(c.cantidad)}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US").format(totalTabla)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default CortesPendientesRecibir;
