import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesMateriales() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_articulos ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_colores ?(
            <Button href="/Colores" className="botonesMenu" color="success">
              <i class="fas fa-palette fa-7x"></i>
              <br />
              <br />
              Colores
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-palette fa-7x"></i>
          <br />
          <br />
          Colores
        </Button> }
          </Col>
         <Col md={4} align="center">
          {user.menu_tipos_telas ?(
            <Button href="/TiposTelas" className="botonesMenu" color="success">
              <i class="fab fa-staylinked fa-7x"></i>
              <br />
              <br />
              Tipo de Tela
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fab fa-staylinked fa-7x"></i>
          <br />
          <br />
          Tipo de Tela
        </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_telas ?(
            <Button href="/ListadoTelas" className="botonesMenu" color="success">
              <i class="fas fa-tape fa-7x"></i>
              <br />
              <br />
              Telas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tape fa-7x"></i>
            <br />
            <br />
            Telas
          </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
            {user.menu_habilitacion ?(
            <Button href="/ListadoHabilitacion" className="botonesMenu" color="success">
              <i class="fas fa-tag fa-7x"></i>
              <br />
              <br />
              Habilitacion
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tag fa-7x"></i>
            <br />
            <br />
            Habilitacion
          </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoInventariosTelas" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario Telas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario Telas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_habilitacion ?(
            <Button href="/ListadoInventariosHabilitacion" className="botonesMenu" color="success">
              <i class="fas fa-tags fa-7x"></i>
              <br />
              <br />
              Inventario Habilitacion
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tags fa-7x"></i>
          <br />
          <br />
          Inventario Habilitacion
        </Button> }
          </Col>
          
          </Row>
          <br />
          <Row>

          </Row>
         
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesMateriales;
