import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  Badge,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function ListadoFaltantesProveedores() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FALTANTES_PROVEEDORES = process.env.REACT_APP_URL_FALTANTES_PROVEEDORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_ABONOS_PROVEEDORES = process.env.REACT_APP_URL_ABONOS_PROVEEDORES;
  const BANCO_FALTANTES = process.env.REACT_APP_BANCO_FALTANTES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [faltantesProveedores, setFaltantesProveedores] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [idFaltanteProveedoresEdit, setidFaltanteProveedoresEdit] = useState("");
  const [numeroEdit, setNumeroEdit] = useState("");
  const [fechaEdit, setFechaEdit] = useState("");
  const [idProveedorEdit, setIdProveedorEdit] = useState("");
  const [importeEdit, setImporteEdit] = useState("");
  const [importeEditViejo, setImporteEditViejo] = useState("");
  const [proveedorEdit, setProveedorEdit] = useState("");
  const [utilizadoEdit, setUtilizadoEdit] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [importe, setImporte] = useState();
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [modalAplicaDevolucion, setModalAplicaDevolucion] = useState(false);
  const toggleAplicaDevolucion = () =>
    setModalAplicaDevolucion(!modalAplicaDevolucion);
  const [proveedor, setProveedor] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [proveedorName, setProveedorName] = useState("");
  const [numero, setNumero] = useState("");
  const [total_costo_edit, setTotalCostoEdit] = useState(0);
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
  
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
    axios
      .get(
        `${URL_FALTANTES_PROVEEDORES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allFaltantesProveedores = response.data;
        let arrayTabla = allFaltantesProveedores
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              proveedor: a.proveedores[0].nombre_comercial,
              idProveedor: a.proveedores[0]._id,
              importe: a.total,
              saldo: a.saldo,
              utilizado: a.utilizado,
              numero: a.idFaltanteProveedores,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setFaltantesProveedores(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  let totalPDF = 0;
  let totalImporteTabla = 0;
  let totalUtilizadoTabla = 0;
  let totalSaldoTabla = 0;

  function PDFTabla() {
    totalPDF = 0;
    const data = faltantesProveedores.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == c.idProveedor)
      ) {
        totalPDF = totalPDF + c.importe;
        return [
          c.fecha,
          c.proveedor,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Detalle de FaltantesProveedores", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Proveedor", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPDF)],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleFaltantesProveedores.pdf");
  }

  function excel() {
    const dataExcel = faltantesProveedores.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == c.idProveedor)
      ) {
        return {
          Fecha: c.fecha,
          Proveedor: c.proveedor,
          Importe: c.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleFaltantesProveedores-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleFaltantesProveedores`,
        sheetFilter: ["Fecha", "Proveedor", "Importe"],
        sheetHeader: ["Fecha", "Proveedor", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    totalPDF = 0;
    const data = faltantesProveedores.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == c.idProveedor)
      ) {
        totalPDF = totalPDF + c.importe;
        return [
          c.fecha,
          c.proveedor,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Detalle de FaltantesProveedores", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Proveedor", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "ToPDFtal", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPDF)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de FaltantesProveedores",
          email: mailTo,
          fileName: "ListaFaltantesProveedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de FaltantesProveedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Utilizado", field: "utilizado", sortable: false },
    { name: "Saldo", field: "saldo", sortable: false },
    { name: "Aplicar", field: "aplicar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter((comment) =>
        comment.proveedor.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedProveedor,
  ]);

  function aplicarFaltante(
    idDev,
    saldo,
    numero,
    idProveedor,
    proveedor,
  ) {
    setIdEdit(idDev);
    setTotalCostoEdit(saldo);
    setProveedor(idProveedor);
    setProveedorName(proveedor);
    setNumero(numero);
    setTotal(0);

    axios
      .get(`${URL_NOTAS_CXP}Proveedor/${idProveedor}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_costo: a.total_costo,
              saldo: a.saldo,
              nota: a.idNotasCxP,
              importe: 0,
              proveedores: a.proveedores[0]._id,
              observaciones: "NA",
              corte: a.procesosEntrada[0].cortes[0].idCorte
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleAplicaDevolucion();
  }

  function savePago() {
    if (total <= total_costo_edit) {
      Swal.fire({
        title: "Estas seguro?",
        text: "Se registrará el abono",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.isConfirmed) {
          toggleProgreso();
          let totalMails = inputFields.length;
          inputFields.map((a) => {
            if (a.importe > 0) {
              axios
                .post(
                  `${URL_ABONOS_PROVEEDORES}AplicarAnticipo`,
                  {
                    fecha: endDate,
                    importe: a.importe,
                    proveedores: proveedor,
                    notasCxP: a.id,
                    bancos: BANCO_FALTANTES,
                    observaciones: a.observaciones,
                    codigoAgrupado,
                    editado:"No",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  axios.patch(
                    `${URL_NOTAS_CXP}/${a.id}`,
                    {
                      proveedores: proveedor,
                      saldo: a.saldo - a.importe,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  axios.patch(
                    `${URL_FALTANTES_PROVEEDORES}AplicarFaltante/${idEdit}`,
                    {
                      saldo: total,
                      utilizado: total,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                });
            } else {
              totalMails = totalMails - 1;
            }
          });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El Importe a aplicar no coincide con el total de la Devolucion",
      });
    }
  }

  const handleChangeInputImporte = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (event.target.value <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotalesAbonos();
  };

  function ActualizaTotalesAbonos() {
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);
    setTotal(TE);
  }

  const handleChangeInputObser = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function EditFaltante(
    _id,
    numero,
    fecha,
    idProveedores,
    importe,
    proveedor,
    utilizado
  ) {
    setidFaltanteProveedoresEdit(_id);
    setNumeroEdit(numero);
    setFechaEdit(fecha);
    setIdProveedorEdit(idProveedores);
    setImporteEdit(importe);
    setImporteEditViejo(importe);
    setProveedorEdit(proveedor);
    setUtilizadoEdit(utilizado);
    toggleEdit();
  }
  
  const EditFaltanteSave = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el Faltante",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_FALTANTES_PROVEEDORES}/${idFaltanteProveedoresEdit}`,
            {
              importe: parseFloat(importeEdit) - parseFloat(importeEditViejo),
              idProveedor: idProveedorEdit,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Faltante",
                detalle: `${proveedorEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  };

  function cancelarFaltante(idFaltanteProveedores) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Faltante!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_FALTANTES_PROVEEDORES}Cancel/${idFaltanteProveedores}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_adminCxP ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    <Button
                      size="sm"
                      href="/MenuAdmin"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Faltantes Proveedores</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Faltantes Maquileros</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Proveedor</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedProveedor}
                      onChange={(e) => {
                        setSelectedProveedor(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {proveedores
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalImporteTabla = totalImporteTabla + c.importe;
                      totalUtilizadoTabla = totalUtilizadoTabla + c.utilizado;
                      totalSaldoTabla = totalSaldoTabla + c.saldo;
                        return (
                          <tr>
                            <td>{c.fecha}</td>
                            <td> {c.proveedor} </td>
                            <td>
                              {" "}
                              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                  c.importe
                                )}{" "}
                            </td>
                            <td>
                              {" "}
                              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                  c.utilizado
                                )}{" "}
                            </td>
                            <td>
                              {" "}
                              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                  c.saldo
                                )}{" "}
                            </td>
                            <td>
                            {c.utilizado == 0 ? (
                              <>
                                {/* <Button
                                size="sm"
                                className="btn"
                                color="info"
                                  onClick={(e) =>
                                    EditFaltante(
                                      c._id,
                                      c.numero,
                                      c.fecha,
                                      c.idProveedor,
                                      c.importe,
                                      c.proveedor,
                                      c.utilizado
                                    )
                                  }
                                >
                                  <i class="fas fa-edit"></i>
                                </Button> */}
                                {/* <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) => cancelarFaltante(c._id)}
                              >
                                <i class="fas fa-ban"></i>
                              </Button> */}
                              </>
                              ) : undefined}
                              {c.saldo > 0 ? (
                                <Button
                                  color="primary"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    aplicarFaltante(
                                      c._id,
                                      c.saldo,
                                      c.numero,
                                      c.idProveedor,
                                      c.proveedor,
                                    )
                                  }
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>
                              ) : undefined}
                            </td>
                          </tr>
                        );
                     

                    })}
                    <tr>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            totalImporteTabla
                          )}
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            totalUtilizadoTabla
                          )}
                      </td>

                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            totalSaldoTabla
                          )}
                      </td>

                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal
            size="xl"
            isOpen={modalAplicaDevolucion}
            toggle={toggleAplicaDevolucion}
          >
            <ModalHeader toggle={toggleAplicaDevolucion}>
              <h4>Aplicar Faltante #{numero}</h4>
              <h4>Proveedor {proveedorName}</h4>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_costo_edit)}
                </Badge>
              </h4>
            </ModalHeader>
            <ModalBody>
              {/* <Form onSubmit={savePago}> */}

              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nota</th>
                    <th>Corte</th>
                    <th>Total</th>
                    <th>Abonos</th>
                    <th>Saldo</th>
                    <th>Importe</th>
                    <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {inputFields.map((a) => (
                    // <div key={a.id}>
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.nota}</td>
                      <td>{a.corte}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            a.total_costo
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            a.total_costo - a.saldo
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                      </td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="number"
                          min="0"
                          step="any"
                          name="importe"
                          value={a.importe}
                          key={a.id}
                          onChange={(e) => {
                            handleChangeInputImporte(a.id, e);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="observaciones"
                          value={a.observaciones}
                          key={a.id}
                          onChange={(e) => {
                            handleChangeInputObser(a.id, e);
                          }}
                        />
                      </td>
                    </tr>
                    // </div>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="negrita" align="center">
                      Total
                    </td>
                    <td className="negrita">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
                <br />
              </Table>

              <br />
              {validaBoton ? (
                <Button className="btn btn-success" onClick={savePago}>
                  {" "}
                  Registrar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              {/* </Form>      */}
            </ModalBody>
          </Modal>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
            <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4>Editar Faltante {numeroEdit} {proveedorEdit}</h4>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={EditFaltanteSave}>
                {/* <Row>
                  <Col md={6}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fechaEdit}
                      required
                      disabled
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col md={6}>
                    <Label>Importe</Label>
                    <Input
                      className="col-sm-12"
                      type="number"
                      placeholder="Importe"
                      value={importeEdit}
                      required
                      onChange={(e) => {
                        setImporteEdit(e.target.value);
                      }}
                    />
                  </Col>
                
                </Row>

                <br />
                <Row>
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>

                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoFaltantesProveedores;
