import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ListadoOrdenesCorte() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ORDENES_CORTE = process.env.REACT_APP_URL_ORDENES_CORTE;
  const URL_ARTICULOS_ORDENES_CORTE =
    process.env.REACT_APP_URL_ARTICULOS_ORDENES_CORTE;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  // Agregar Tela a Orden de Corte
  const URL_TELAS_ORDENES_CORTE = process.env.REACT_APP_URL_TELAS_ORDENES_CORTE;
  const URL_TELAS_ORDENES_CORTES_ACUMULADOS = process.env.REACT_APP_URL_TELAS_ORDENES_CORTES_ACUMULADOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;

  let hoy = new Date() 
  let endDate   = moment(hoy).format("YYYY-MM-DD");

  const [ordenesCorte, setOrdenesCorte] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [articulosFiltro, setArticulosFiltro] = useState([]);

  const [inventarios, setInventarios] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();

  const [validaBoton, setValidaBoton] = useState(true);

  const [fechaTelas, setFechaTelas] = useState(endDate);
  const [observacionesTelas, setObservacionesTelas] = useState("NA");
  const [selectedOrdenCorte, setSelectedOrdenCorte] = useState("");
  const [selectedOrdenCorteNumero, setSelectedOrdenCorteNumero] = useState("");
  const [totalMetros, setTotalMetros] = useState("");
  const [totalKilos, setTotalKilos] = useState("");
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);
  const [articulosOrden, setArticulosOrden] = useState([]);
  const [rollosTotales, setRollosTotales] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);

  const [modalTelas, setModalTelas] = useState(false);
  const toggleTelas = () => setModalTelas(!modalTelas);

  const [explosion, setExplosion] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");
  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventarioTelas: "",
      telas: "",
      idTela: "",
      unidad: "",
      colores: "",
      idColor: "",
      cantidad: 0,
      idArticulo: "",
    },
  ]);


  const [inputFieldsAnterior, setInputFieldsAnterior] = useState([]);

  useEffect(() => {
    axios
      .get(URL_ORDENES_CORTE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allOrdenes = response.data;
        // Array para pagination
        let arrayTabla = allOrdenes
          .sort((a, b) => (a.idOrdenesCorte > b.idOrdenesCorte ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idOrdenesCorte,
              fecha: a.fecha,
              pedido: a.pedidos[0].idPedido,
              cortado: a.cortado,
              articulo: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente,
              idArticulo: a.articulos[0]._id
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setOrdenesCorte(allOrdenes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS_TELAS}Existencia`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventarios = response.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaOrdenesCorte = comments.sort((a, b) =>
    a.idOrdenCorte < b.idOrdenCorte ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaOrdenesCorte.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return [a.numero, a.fecha, a.pedido];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Corte`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Pedido"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Ordenes-Corte.pdf`);
  }

  function excel() {
    const dataExcel = ListaOrdenesCorte.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Pedido: a.pedido,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoOrdenesCorte";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoOrdenesCorte",
        sheetFilter: ["Numero", "Fecha", "Pedido"],
        sheetHeader: ["Numero", "Fecha", "Pedido"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaOrdenesCorte.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return [a.numero, a.fecha, a.pedido];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Corte`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Pedido"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ordenes Corte",
          email: mailTo,
          fileName: "ListadoOrdenesCorte.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ordenes de Corte.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Pedido", field: "pedido", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Tela", field: "Tela", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) ||
          comment.pedido.toString().includes(search)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (articulosFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(articulosFiltro)
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "numero" &&
      sorting.field != "pedido"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "numero" || sorting.field == "pedido")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "numero" || sorting.field == "pedido")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    articulosFiltro
  ]);

  let totalCantidad = 0;
  let totalCortado = 0;
  let totalPendCortar = 0;

  async function PDFOC(numero, fecha, pedido, idPDFOC) {
    await axios
      .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            totalCantidad = totalCantidad + a.cantidad;
            totalCortado = totalCortado + a.cortado;
            totalPendCortar = totalPendCortar + a.pendiente_cortar;
            return {
              id: a._id,
              articulo:
                a.articulosPedido[0].articulos[0].codigo + " / " + a.articulosPedido[0].articulos[0].codigoCliente +
                " " +
                a.articulosPedido[0].articulos[0].nombre +
                " " +
                a.articulosPedido[0].colores[0].name +
                " " +
                a.articulosPedido[0].tallas[0].name,
              cantidad: a.cantidad,
              cortado: a.cortado,
              pendiente_cortar: a.pendiente_cortar,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = arrayTabla.map((a) => {
          return [
            a.articulo,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.cortado),
            new Intl.NumberFormat("en-US").format(a.pendiente_cortar),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Numero Orden de Corte # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Pedido ${pedido}`, 20, 30);
        doc.autoTable({
          head: [["Articulo", "Cantidad", "Cortado", "Pendiente Cortar"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "Totales",
              new Intl.NumberFormat("en-US").format(totalCantidad),
              new Intl.NumberFormat("en-US").format(totalCortado),
              new Intl.NumberFormat("en-US").format(totalPendCortar),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`OrdenCorte-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function requisicion(id, numero){
    setExplosion([])
    toggleProgreso()
      setIdEdit(id);
      setNumeroPedido(numero);
      axios
      .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then( async (response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = await allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulosPedido[0].articulos[0]._id,
              articulosCodigo: a.articulosPedido[0].articulos[0].codigo + " / " + a.articulosPedido[0].articulos[0].codigoCliente,
              colores: a.articulosPedido[0].colores[0]._id,
              coloresName: a.articulosPedido[0].colores[0].name,
              cantidad: a.cantidad,
              surtido: a.articulosPedido[0].surtido,
              pendiente_surtir: a.articulosPedido[0].pendiente_surtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });
    
        let data = Object.values(arrayTabla);
    
        let agrupado = data.reduce(function (groups, item) {
          const val = item["articulos"]
          groups[val] = groups[val] || {
            articulos: item.articulos,
            cantidad:0
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          return groups;
        },[])
    
        let dataFinal = Object.values(agrupado);
    
        let agrupadoColores = data.reduce(function (groups, item) {
          const val = item["articulos"] + item["colores"]
          groups[val] = groups[val] || {
            articulos: item.articulos,
            colores: item.colores,
            cantidad:0
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;     
          groups[val].colores = item.colores;     
          groups[val].coloresName = item.coloresName;     
          return groups;
        },[])
    
        let dataFinalColores = Object.values(agrupadoColores);
    
        let temp2 =[]
    
      let exp = await dataFinal.map((a)=>{
          axios
          .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }).then((response)=>{
            let allExplosion = response.data;
            let arrayExp = allExplosion.map((b)=>{
              if(b.habilitacion!=""){
                temp2.push({
                articulos: a.articulos,
                articulosCodigo: a.articulosCodigo,
                material: b.habilitacion[0].nombre,
                cantidad: b.cantidad * a.cantidad,
                colores: "NA",
                coloresName: "NA"})
              }
              
            })
            .filter(function (el) {
              return el != null;
            });
    
    
          }).catch((err) => {
            console.log(err);
          });
    
        })
    
        let expColores = await dataFinalColores.map((a)=>{
          axios
          .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }).then((response)=>{
            let allExplosion = response.data;
            let arrayExp = allExplosion.map((b)=>{
              if(b.telas!=""){
              temp2.push({
                articulos: a.articulos,
                articulosCodigo: a.articulosCodigo,
                material: b.telas[0].nombre,
                cantidad: b.cantidad * a.cantidad,
                colores: a.colores,
                coloresName: a.coloresName
              })}
            })
            .filter(function (el) {
              return el != null;
            });
    
          }).catch((err) => {
            console.log(err);
          });
    
        })
            setExplosion(temp2)
            setTimeout(() => {
              setModalProgreso(false)
              toggleExplosion()
            }, 1000);
            
      })
      .catch((err) => {
        console.log(err);
      });
    
      
    }

  function telasCortes(id, numero) {
    console.log(id);

    axios
      .get(`${URL_TELAS_ORDENES_CORTES_ACUMULADOS}/ordenCorte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInputFieldsAnterior = response.data;
        let tablaTelas = allInputFieldsAnterior.map((a)=>{
          return{
            articulo: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " / " + a.articulos[0].nombre,
            tela: a.telas[0].nombre,
            color: a.colores[0].name,
            unidad: a.telas[0].unidad,
            cantidad: a.cantidad
          }
        })
        let dataFinal = Object.values(tablaTelas);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["tela"] + item["color"]
          groups[val] = groups[val] || {
            tela: item.tela,
            color: item.color,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].tela = item.tela;
          groups[val].unidad = item.unidad;
          groups[val].color = item.color;
          groups[val].articulo = item.articulo;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);



        setInputFieldsAnterior(agrupadoValues);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            totalCantidad = totalCantidad + a.cantidad;
            totalCortado = totalCortado + a.cortado;
            totalPendCortar = totalPendCortar + a.pendiente_cortar;
            return {
              id: a._id,
              articuloNombre:
                a.articulosPedido[0].articulos[0].codigo + " / " + a.articulosPedido[0].articulos[0].codigoCliente +
                " / " +
                a.articulosPedido[0].articulos[0].nombre,
              idArticulo: a.articulosPedido[0].articulos[0]._id,
              cantidad: a.cantidad,
              cortado: a.cortado,
              pendiente_cortar: a.pendiente_cortar,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosOrden(data);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].articuloNombre = item.articuloNombre;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicos(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedOrdenCorte(id);
    setSelectedOrdenCorteNumero(numero);
    toggleTelas();
  }

  let totalCantidadMt = 0
  let totalCantidadKg = 0
  async function PDFTelas(numero, fecha, pedido, id) {
    await axios
      .get(`${URL_TELAS_ORDENES_CORTES_ACUMULADOS}/ordenCorte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            if(a.telas[0].unidad == "Metros"){
            totalCantidadMt = totalCantidadMt + a.cantidad
          }else{
            totalCantidadKg = totalCantidadKg + a.cantidad
          }
            return {
              id: a._id,
              articulo: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " / " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              unidad: a.telas[0].unidad,
              telaNombre: a.telas[0].nombre,
              colorNombre: a.colores[0].name,
              totalKilos: a.telasOrdenesCorte[0].totalKilos,
              totalMetros: a.telasOrdenesCorte[0].totalMetros,
              // setTotalKilosPDF(a.totalKilos),
              // setTotalMetrosPDF(a.totalMetros)
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = arrayTabla.map((a) => {
          return [
            a.articulo,
            a.telaNombre,
            a.colorNombre,
            new Intl.NumberFormat("en-US").format(a.cantidad) + " " + a.unidad,
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Numero Orden de Corte # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha OC: ${fecha}`, 20, 25);
        doc.text(`Pedido: ${pedido}`, 20, 30);
        doc.text(`Cantidad: ${totalCantidadMt} Mts ${totalCantidadKg} Kgs`, 20, 35);
        doc.autoTable({
          head: [["Articulo", "Tela", "Color", "Cantidad"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [],
          ],
          showFoot: "lastPage",
        });
        doc.save(`OrdenCorte-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function BuscaRollo(id, event) {
    if (event.target.value.length >= 24) {
      if (
        inputFields.filter((e) => e.idInventarioTelas == event.target.value)
          .length > 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo ya fue escaneado!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventarioTelas = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      } else {
        inventarios.map((a) => {
          if (a._id == event.target.value) {
            let idInventarioTelas = a._id;
            let telas = a.telas[0].codigo;
            let idTela = a.telas[0]._id;
            let unidad = a.telas[0].unidad;
            let color = a.colores[0].name;
            let idColor = a.colores[0]._id;
            let cantidad = a.cantidad;
            handleChangeInputTelas(
              id,
              idInventarioTelas,
              telas,
              idTela,
              unidad,
              color,
              idColor,
              cantidad,
            );
          }
        });
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventarioTelas = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputTelas = (
    id,
    idInventarioTelas,
    telas,
    idTela,
    unidad,
    color,
    idColor,
    cantidad,
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventarioTelas = idInventarioTelas;
        i.telas = telas;
        i.idTela = idTela;
        i.unidad = unidad;
        i.colores = color;
        i.idColor = idColor;
        i.cantidad = cantidad;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColor"] + item["idTela"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColor: item.idColor,
        idTela: item.idTela,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].telas = item.telas;
      groups[val].idTela = item.idTela;
      groups[val].idArticulo = item.idArticulo;
      groups[val].unidad = item.unidad;
      groups[val].idColor = item.idColor;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);

    let tr = 0;
    inputFields.map((a) => {
      if (a.idArticulo != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];

    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventarioTelas: "",
        telas: "",
        idTela: "",
        unidad: "",
        colores: "",
        idColor: "",
        cantidad: 0,
        idArticulo: ultimo.idArticulo,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
  };

  const handleChangeInputArticulo = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const saveTelas = async (event) => {
    event.preventDefault();
    if (fechaTelas != "" && observacionesTelas != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFields.length + cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_TELAS_ORDENES_CORTE,
            {
              fecha: fechaTelas,
              ordenesCorte: selectedOrdenCorte,
              observaciones: observacionesTelas,
              totalMetros,
              totalKilos,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.idTela != "") {
                axios
                  .patch(
                    `${URL_INVENTARIOS_TELAS}/ordenesCorte/${a.idInventarioTelas}`,
                    {
                      ordenesCorte: selectedOrdenCorte,
                      produccion: "Si",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Telas Ordenes Corte",
                            detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Telas Ordenes Corte",
                        detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });

            // Pegamos a Apartados Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_TELAS_ORDENES_CORTES_ACUMULADOS}`,
                  {
                    telasOrdenesCorte: data.data._id,
                    articulos: w.idArticulo,
                    telas: w.idTela,
                    colores: w.idColor,
                    cantidad: w.cantidad,
                    ordenesCorte: selectedOrdenCorte,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Telas Ordenes Corte",
                          detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a apartados acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  function cortado(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcará como cortado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Marcar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_ORDENES_CORTE}/${id}`,
            {
              cortado: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ).then(()=>{
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.cortado = "Si"
              }
              return i;
            });
            setComments(newComments);

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Cerró",
              showConfirmButton: false,
              timer: 1000,
            });

            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          }).catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function noCortado(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcará como no cortado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Marcar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_ORDENES_CORTE}/${id}`,
            {
              cortado: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ).then(()=>{
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.cortado = "No"
              }
              return i;
            });
            setComments(newComments);

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Abrió",
              showConfirmButton: false,
              timer: 1000,
            });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          }).catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_produccion ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/OrdenesCorteCreate"
                >
                  Nueva Orden
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Orden
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuProduccion"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Corte</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ordenes de Corte</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Articulo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={articulosFiltro}
                onChange={(e) => {
                  setArticulosFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.codigo} / {a.codigoCliente}
                      </option>
                    );
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.articulo}</td>
                      <td>{a.pedido}</td>
                      <td>
                        <>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFOC(a.numero, a.fecha, a.pedido, a._id)
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => requisicion(a._id, a.numero)}
                            >
                              <i class="fas fa-tag"></i>
                            </Button>
                            {a.cortado == "No" ? (
                                <span>
                                  <Button
                                    color="danger"
                                    id="Cerrar"
                                    onClick={(e) =>cortado(a._id)}
                                    size="sm"
                                  >
                                  <i class="fas fa-cut"></i>
                                  </Button>
                                </span>
                              ) : (
                                <span>
                                  <Button color="primary" onClick={(e) =>noCortado(a._id)} size="sm">
                                    <i class="fas fa-cut"></i>
                                  </Button>
                                </span>
                              )}
                              </>
                              </td>
                              <td>
                        <Button
                          color="info"
                          id="Telas"
                          size="sm"
                          onClick={(e) => telasCortes(a._id, a.numero)}
                        >
                          <i class="fas fa-cart-plus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                              PDFTelas(a.numero, a.fecha, a.pedido, a._id)
                          }
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                       
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalTelas} toggle={toggleTelas}>
        <ModalHeader toggle={toggleTelas}>
          <h4>
            Entrega de Tela de la Orden de Corte {selectedOrdenCorteNumero}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveTelas}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaTelas}
                  required
                  onChange={(e) => {
                    setFechaTelas(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observacionesTelas}
                  required
                  onChange={(e) => {
                    setObservacionesTelas(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {inputFieldsAnterior != "" ? (
              <>
              <Label>Tela Asignada</Label>
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulo</Label>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Tela</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Color</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Unidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                </Row>

                {inputFieldsAnterior.map((inputFieldAnt) => (
                  <div key={inputFieldAnt.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.articulo}
                          disabled
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.tela}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.color}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.unidad}
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.cantidad}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            ) : undefined}
            <br />

            <Label>Asignar Tela</Label>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.idArticulo}
                      onChange={(event) => {
                        handleChangeInputArticulo(inputField.id, event);
                      }}
                      tabindex="-1"
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) =>
                          a.articuloNombre > b.articuloNombre ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>
                              {a.articuloNombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventarioTelas"
                      type="string"
                      value={inputField.idInventarioTelas}
                      onChange={(event) => {
                        BuscaRollo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(totalKilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(totalMetros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>
            <br />

            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalExplosion} toggle={toggleExplosion}>
            <ModalHeader toggle={toggleExplosion}>
              <h4>Explosion</h4>
            </ModalHeader>
            <ModalBody>
             <Table size="sm" striped borderless className="table-responsive-xl">
             <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Color</th>
              <th>Cantidad</th>
            </tr>
             {explosion.map((a)=>(
              <tr>
              <td>{a.articulosCodigo}</td>
              <td>{a.material}</td>
              <td>{a.coloresName}</td>
              <td>{a.cantidad}</td>
              </tr>
             ))}
             </Table>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoOrdenesCorte;
