import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Get from "../../Get";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_TEMPORADAS = process.env.REACT_APP_URL_TEMPORADAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CATALOGO_PROCESOS = process.env.REACT_APP_URL_CATALOGO_PROCESOS;

  const [codigo, setCodigo] = useState("");
  const [codigoCliente, setCodigoCliente] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [precioEtiqueta, setPrecioEtiqueta] = useState(0);
  const [venta, setVenta] = useState(0);
  const [observaciones, setObservaciones] = useState("NA");
  const [observacionesProduccion, setObservacionesProduccion] = useState("NA");
  const [lineas, setLineas] = useState([]);
  const [temporadas, setTemporadas] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedTemporada, setSelectedTemporada] = useState("");
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [tallas, setTallas] = useState([]);
  const [selectedTalla, setSelectedTalla] = useState("");
  const [catalogoProcesos, setCatalogoProcesos] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedPatronista, setSelectedPatronista] = useState("");
  const [selectedMuestrista, setSelectedMuestrista] = useState("");
  const [selectedPloter, setSelectedPloter] = useState("");

  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [modalTallas, setModalTallas] = useState(false);
  const toggleTallas = () => setModalTallas(!modalTallas);
  const [arrayTallas, setArrayTallas] = useState([]);

  const [modalProcesos, setModalProcesos] = useState(false);
  const toggleProcesos = () => setModalProcesos(!modalProcesos);
  const [arrayProcesos, setArrayProcesos] = useState([
    "636abcc96b200f00167eccbd",
  ]);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TEMPORADAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTemporadas = res.data;
        setTemporadas(allTemporadas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(URL_CATALOGO_PROCESOS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allCatalogoProcesos = res.data;
      setCatalogoProcesos(allCatalogoProcesos);
    })
    .catch((err) => {
      console.log(err);
    });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function coloresSeleccionados(checked, idColor) {
    if (checked) {
      arrayColores.push(idColor);
    } else {
      const index = arrayColores.indexOf(idColor);
      if (index > -1) {
        arrayColores.splice(index, 1);
      }
    }
  }

  function tallasSeleccionados(checked, idColor) {
    if (checked) {
      arrayTallas.push(idColor);
    } else {
      const index = arrayTallas.indexOf(idColor);
      if (index > -1) {
        arrayTallas.splice(index, 1);
      }
    }
  }
  function procesosSeleccionados(checked, idColor) {
    if (checked) {
      arrayProcesos.push(idColor);
    } else {
      const index = arrayProcesos.indexOf(idColor);
      if (index > -1) {
        arrayProcesos.splice(index, 1);
      }
    }
  }

  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      if(file == null){
      await axios
        .post(
          URL_ARTICULOS,
          {
            codigoCliente,
            codigo,
            nombre,
            descripcion,
            venta,
            observaciones,
            lineas: selectedLinea,
            temporadas: selectedTemporada,
            colecciones: selectedColeccion,
            precioEtiqueta,
            observacionesProduccion,
            colores: arrayColores,
            tallas: arrayTallas,
            catalogoProcesos: arrayProcesos.filter((item, index) => {
              return arrayProcesos.indexOf(item) === index;
            }),
            patronista: selectedPatronista,
            muestrista: selectedMuestrista,
            ploter: selectedPloter,
            // catalogoProcesos: ["636abcc96b200f00167eccbd"]
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Modelo",
              detalle: `${nombre} ${codigo}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
      }else{
        
      await axios
      .post(
        URL_ARTICULOS,
        {
          codigoCliente,
          codigo,
          nombre,
          descripcion,
          venta,
          observaciones,
          lineas: selectedLinea,
          temporadas: selectedTemporada,
          colecciones: selectedColeccion,
          precioEtiqueta,
          observacionesProduccion,
          colores: arrayColores,
          tallas: arrayTallas,
          catalogoProcesos: arrayProcesos.filter((item, index) => {
            return arrayProcesos.indexOf(item) === index;
          }),
          patronista: selectedPatronista,
          muestrista: selectedMuestrista,
          ploter: selectedPloter,
          // catalogoProcesos: ["636abcc96b200f00167eccbd"]
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )

      .then((data) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("articulo", data.data._id);

        axios
          .post(URL_FILEPOST, formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {

        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Modelo",
            detalle: `${nombre} ${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function coloresInfo() {
    toggleColores();
  }

  function tallasInfo() {
    toggleTallas();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.articulos_create ? (
        <div className="card container">
          <h3 align="center">Nuevo Modelo</h3>
          {/* <Get/> */}
          <Form onSubmit={saveArticulos}>
            <Row>
              <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Codigo Cliente</Label>
                <Input
                  type="text"
                  placeholder="CodigoCliente"
                  value={codigoCliente}
                  required
                  onChange={(e) => {
                    setCodigoCliente(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Modelo</Label>
                <Input
                  type="text"
                  placeholder="Modelo"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="text"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Precio Etiqueta</Label>
                <Input
                  type="text"
                  placeholder="Precio Etiqueta"
                  value={precioEtiqueta}
                  required
                  onChange={(e) => {
                    setPrecioEtiqueta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Temporada</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTemporada}
                  required
                  onChange={(e) => {
                    setSelectedTemporada(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {temporadas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Linea</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedLinea}
                  required
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccion}
                  required
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Observaciones</Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Observaciones Produccion</Label>
                <Input
                  type="text"
                  placeholder="Observaciones Produccion"
                  value={observacionesProduccion}
                  required
                  onChange={(e) => {
                    setObservacionesProduccion(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>Patronista</Label>
                <Input
                  type="select"
                  value={selectedPatronista}
                  onChange={(e) => {
                    setSelectedPatronista(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Muestrista</Label>
                <Input
                  type="select"
                  value={selectedMuestrista}
                  onChange={(e) => {
                    setSelectedMuestrista(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Ploter</Label>
                <Input
                  type="select"
                  value={selectedPloter}
                  onChange={(e) => {
                    setSelectedPloter(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
            <Col md={3}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
            </Row>
            <br />
            <Row>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Agregar Colores
                </Label>
                <br />
                <Button
                  color="success"
                  id="Colores"
                  onClick={(e) => coloresInfo()}
                >
                  <i class="fas fa-palette fa-2x "></i>
                </Button>
              </Col>

              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Agregar Tallas
                </Label>
                <br />
                <Button
                  color="success"
                  id="Tallas"
                  onClick={(e) => tallasInfo()}
                >
                  <i class="fas fa-ruler-horizontal fa-2x "></i>
                </Button>
              </Col>
              <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Procesos
                    </Label>
                    <br />
                    <Button
                      color="success"
                      id="Procesos"
                      onClick={(e) => toggleProcesos()}
                    >
                      <i class="fas fa-tshirt fa-2x "></i>
                    </Button>
                  </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

          <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
            <ModalHeader toggle={toggleColores}>
              <h4>Colores del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {colores
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a, index) => {
                  if (!arrayColores.includes(a._id)) {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              coloresSeleccionados(
                                e.currentTarget.checked,
                                a._id
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                  } else {
                    return <h5 className="subMenuTitulo">{a.name}</h5>;
                  }
                })}
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={modalTallas} toggle={toggleTallas}>
            <ModalHeader toggle={toggleTallas}>
              <h4>Tallas del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {tallas
                // .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a, index) => {
                  if (!arrayTallas.includes(a._id)) {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              tallasSeleccionados(
                                e.currentTarget.checked,
                                a._id
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                  } else {
                    return <h5 className="subMenuTitulo">{a.name}</h5>;
                  }
                })}
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={modalProcesos} toggle={toggleProcesos}>
            <ModalHeader toggle={toggleProcesos}>
              <h4>Procesos del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {catalogoProcesos
                // .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a, index) => {
                  if (a._id != "636abcc96b200f00167eccbd") {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              procesosSeleccionados(
                                e.currentTarget.checked,
                                a._id
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                  } else {
                    return <h5 className="subMenuTitulo">{a.name}</h5>;
                  }
                })}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
