import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;

  // let hoy = new Date()
  // let inicio = new Date()
  // inicio.setDate(inicio.getDate()-30);
 
  // let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  // let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [comprador, setComprador] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [value, setValue] = useState("");

  const [idArticulo, setIdArticulo] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState("");
  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      resurtido:"",
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      tc: [],
      totalArt: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    
    let inicio = new Date(fecha_cancelacion)
    let bla = inicio.setDate(inicio.getDate()-60);
    let fechaStatus = moment(bla).format("YYYY-MM-DD")


    let totalPedido = inputFields.length;

    try {
      await axios
        .post(
          URL_PEDIDOS,
          {
            fecha,
            clientes: value._id,
            colaboradores: selectedColaborador,
            fecha_cancelacion,
            fecha_confirmacion,
            total_general,
            observaciones,
            total_piezas,
            comprador,
            pedidoCliente,
            fechaEstCodigos: fechaStatus,
            fechaRealCodigos: fechaStatus,
            fechaEstFicha: fechaStatus,
            fechaRealFicha: fechaStatus,
            fechaEstAuditoria: fechaStatus,
            fechaRealAuditoria: fechaStatus,
            fechaEstMuestraFit: fechaStatus,
            fechaRealMuestraFit: fechaStatus,
            fechaEstMuestraConfirmacion: fechaStatus,
            fechaRealMuestraConfirmacion: fechaStatus,
            fechaEstEtiquetas: fechaStatus,
            fechaRealEtiquetas: fechaStatus,
            articulos: idArticulo,
            surtido: 0,
            pendiente_surtir: total_piezas
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.totalArt != 0) {
              a.tc.map((b) => {
                axios
                  .post(
                    URL_ARTICULOS_PEDIDO,
                    {
                      pedidos: data.data._id,
                      articulos: a.articulos,
                      colores: b.colores,
                      tallas: b.tallas,
                      cantidad: parseFloat(b.cantidad),
                      pendienteCortar: parseFloat(b.cantidad),
                      cortado: 0,
                      surtido: 0,
                      pendiente_surtir: parseFloat(b.cantidad),
                      cantidad_procesos: parseFloat(b.cantidad),
                      precio: parseFloat(a.precio),
                      descuento: parseFloat(a.descuento),
                      total: parseFloat(b.cantidad) * parseFloat(a.precio),
                      resurtido: a.resurtido
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalPedido = totalPedido - 1;
                    if (totalPedido == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Pedido",
                            detalle: `${value._id} ${total_general}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", `success`);
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        });
                    }
                  });
              });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Pedido",
                      detalle: `${value._id} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // const handleAddFields = () => {
  //   setInputFields([
  //     ...inputFields,
  //     {
  //       id: uuidv4(),
  //       articulos: "",
  //       resurtido:"",
  //       colores: "",
  //       arrayColoresArticulo: [],
  //       tallas: "",
  //       arrayTallasArticulo: [],
  //       cantidad: 0,
  //       precio: 0,
  //       descuento: 0,
  //       total: 0,
  //       tc: [],
  //       totalArt: 0,
  //     },
  //   ]);
  // };

  // const handleRemoveFields = (id) => {
  //   const values = [...inputFields];
  //   values.splice(
  //     values.findIndex((value) => value.id === id),
  //     1
  //   );
  //   setInputFields(values);

  //   let cantidad = inputFields.map((c) => parseFloat(c.cantidad));
  //   let TC = cantidad.reduce((t, total, index) => t + total, 0);
  //   setTotalPiezas(TC);

  //   let importes = values.map((c) => parseFloat(c.total));
  //   let TG = importes.reduce((t, total, index) => t + total, 0);
  //   setTotalGeneral(TG);
  // };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.totalArt * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, colores, tallas, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.tc.map((a) => {
          if (a.colores == colores && a.tallas == tallas) {
            a.cantidad = event.target.value;
          }
        });

        let can = i.tc.map((c) => parseFloat(c.cantidad));
        let TC = can.reduce((t, total, index) => t + total, 0);

        i.totalArt = TC;
        let totalParcial = i.totalArt * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let precio = a.venta;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputArticulo(
          id,
          idArticulo,
          precio,
          coloresArticulo,
          tallasArticulo
        );
        setIdArticulo(event.target.value)
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    precio,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newInputFields2 = [];

    let coloresArray = Object.values(coloresArticulo);
    let tallasArray = Object.values(tallasArticulo);

    coloresArray.map((a) => {
      tallasArray.map((b) => {
        newInputFields2.push({
          colores: a._id,
          colorNombre: a.name,
          tallas: b._id,
          tallaNombre: b.name,
          cantidad: 0,
        });
      });
    });

    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = precio;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.tc = newInputFields2;
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidad = inputFields.map((c) => parseFloat(c.totalArt));
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  const options = clientes.map((option) => {
    const junta = option.razon_social;
    const firstLetter = option.razon_social[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function sortArrayOfGradings(array) {

    function parseGradingOrder(grading) {
        let order;
        if (grading.includes('ch'))
            order = -1;
        else if (grading.includes('m'))
            order = 0;
        else if (grading.includes('g'))
            order = 1;
        const n = Number(grading.match(/\d+(?!X)/))
        const numXes = grading.match(/x*/)[0].length
        const mul = n ? n : numXes + 1
        return order * mul;
    }

    return array.sort((a, b) => {
        if (!isNaN(a.tallaNombre) && !isNaN(b.tallaNombre))
              return a.tallaNombre-b.tallaNombre;
        if (!isNaN(a.tallaNombre) && isNaN(b.tallaNombre))
            return -1
        if (isNaN(a.tallaNombre) && !isNaN(b.tallaNombre))
            return 1
        if (isNaN(a.tallaNombre) && isNaN(b.tallaNombre)) {
            let aOrder = parseGradingOrder(a.tallaNombre.toLowerCase());
            let bOrder = parseGradingOrder(b.tallaNombre.toLowerCase());
            return aOrder-bOrder;
        }
    });
}


sortArrayOfGradings(inputFields[0].tc)



  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Pedido</h3>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                  size="small"
                  value={value}
                  onChange={(event, selectedCliente) => {
                    setValue(selectedCliente);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>{option.razon_social}</React.Fragment>
                  )}
                />
              </Col>

              <Col md={3}>
                <Label>Vendedor</Label>
                <Input
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value="">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      if (a.puestos[0].name == "Ventas") {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha Cancelacion</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cancelacion"
                  value={fecha_cancelacion}
                  required
                  onChange={(e) => {
                    setFechaCancelacion(e.target.value);
                    let inicio = new Date(e.target.value)
                    let bla = inicio.setDate(inicio.getDate()-45);
                    setFechaConfirmacion(moment(bla).format("YYYY-MM-DD"))
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Confirmacion</Label>
                <Input
                  type="date"
                  placeholder="Fecha Confirmacion"
                  value={fecha_confirmacion}
                  required
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Comprador
                </Label>
                <Input
                  type="text"
                  placeholder="Comprador"
                  value={comprador}
                  required
                  onChange={(e) => {
                    setComprador(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Pedido Cliente
                </Label>
                <Input
                  type="text"
                  placeholder="Pedido Cliente"
                  value={pedidoCliente}
                  required
                  onChange={(e) => {
                    setPedidoCliente(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Resurtido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Descuento</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} / {a.codigo}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="resurtido"
                      type="select"
                      value={inputField.resurtido}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={inputField.descuento}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="totalArt"
                      type="number"
                      placeholder="Piezas"
                      value={inputField.totalArt}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  {/* <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col> */}
                </Row>
                {inputField.arrayColoresArticulo.map((col) => {
                  return (
                    <>
                      <Row>
                        <Col md={9} className="tallas">
                          <Col md={1}>
                            <Label>{col.name}</Label>
                          </Col>
                          {inputField.tc.map((a) => {
                            if (a.colores == col._id) {
                              return (
                                <>
                                  <Col md={1}>
                                    <Label>{a.tallaNombre}</Label>
                                    <Input
                                      bsSize="sm"
                                      name="cantidad"
                                      type="number"
                                      value={a.cantidad}
                                      required
                                      onChange={(event) =>
                                        handleChangeInputCantidad(
                                          inputField.id,
                                          a.colores,
                                          a.tallas,
                                          event
                                        )
                                      }
                                    />
                                  </Col>
                                </>
                              );
                            }
                          })}
                        </Col>
                      </Row>
                    </>
                  );
                })}
                <br />
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoPedidos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosCreate;
